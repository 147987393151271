<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <station-form></station-form>
  </div>
</template>

<script>
import { GET_STATION } from "@/core/services/store/stations.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import stationForm from "@/view/project/stations/components/StationForm.vue";
import customBreadcrumb from "@/view/custom/breadcrumb";

export default {
  name: "stations.edit",
  components: {
    customBreadcrumb,
    stationForm,
  },
  data() {
    return {
      customBreadcrumb: {
        title: "Santraller",
        type: "Düzenle",
        pathName: null,
      },
    };
  },
  created() {
    this.$store.dispatch(GET_STATION, this.$route.params.id).then((res) => {
      this.$children[1].$data.id = parseInt(this.$route.params.id);
      this.$children[1].$data.name = res.powerStationsInfo.name;
      this.$children[1].$data.area = res.powerStationsInfo.area;
      this.$children[1].$data.altitude = res.powerStationsInfo.altitude;
      this.$children[1].$data.latitude = res.powerStationsInfo.latitude;
      this.$children[1].$data.longitude = res.powerStationsInfo.longitude;
      this.$children[1].$data.installed_power =
        res.powerStationsInfo.installed_power;
      this.$children[1].$data.selected_license = {
        license_status: res.powerStationsInfo.license_status,
        text: null,
      };
      this.$children[1].$data.selected_station_type = {
        power_station_type_id: res.powerStationsInfo.power_station_type_id,
        text: null,
      };
      this.$children[1].$data.selected_cities = {
        city_id: res.powerStationsInfo.city_id,
        text: null,
      };
      this.$children[1].$data.selected_counties = {
        county_id: res.powerStationsInfo.county_id,
        text: null,
      };
      //Seçili Cihazları Getirme
      if (res.powerStationsInfo.selected_devices != "") {
        let selected_devices_array = res.powerStationsInfo.selected_devices.split(';');

        this.$children[1].$data.selected_devices_default = selected_devices_array;
        this.$children[1].$data.selected_devices = selected_devices_array;
      }
    });
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Santraller", route: "/stations", icon: "flaticon-car" },
      { title: "Düzenle" },
    ]);
  },
};
</script>
